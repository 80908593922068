// App.js
import React from "react";
import Routes from "./routes/Routes";
import { UserProvider } from "./firebase/UserContext";
import { SpeedInsights } from '@vercel/speed-insights/react';


function App() {
  return (
    <UserProvider> {/* Wrap the Routes with UserProvider */}
      <div className="App">
        <Routes />
        <SpeedInsights />
      </div>
    </UserProvider>
  );
}

export default App;

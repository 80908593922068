import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db, auth } from "../../../firebase/firebaseConfig";
import {
  onSnapshot,
  doc,
  collection,
  addDoc,
  getDoc,
  query,
  orderBy,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai"

const TaskDetailsPage = () => {
  const { taskId } = useParams();
  const [task, setTask] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [userName, setUserName] = useState(""); // NEW: Store the user's name
  const [userCache, setUserCache] = useState({});

  const formatDateAndTime = (timestamp) => {
    const date = timestamp.toDate();
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  useEffect(() => {
    // Fetch the task details
    const taskDocRef = doc(db, `users/${auth.currentUser.uid}/tasks`, taskId);
    const unsubscribeTask = onSnapshot(taskDocRef, (doc) => {
      setTask(doc.data());
    });

    // Fetch user details to get the name
    const userDocRef = doc(db, "users", auth.currentUser.uid);
    const unsubscribeUser = onSnapshot(userDocRef, (doc) => {
      setUserName(doc.data().name);
    });

    // Fetch the comments for this task
    const commentsQuery = query(
      collection(db, `users/${auth.currentUser.uid}/tasks/${taskId}/comments`),
      orderBy("timestamp", "desc")
    );
    const unsubscribeComments = onSnapshot(commentsQuery, (snapshot) => {
      const fetchedComments = [];
      snapshot.forEach((doc) => {
        fetchedComments.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setComments(fetchedComments);
    });

    return () => {
      unsubscribeTask();
      unsubscribeComments();
      unsubscribeUser(); // Unsubscribe from user data as well
    };
  }, [db, taskId]);

  const handleAddComment = async (e) => {
    if (e && e.key && e.key !== 'Enter') {
      return;
    }

    if (newComment.trim() === "") return;

    const fetchUserNameAndImage = async (userId) => {
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.data();

      setUserCache((prev) => ({
        ...prev,
        [userId]: {
          name: userData.name,
          image: userData.image, // assuming image URL is stored under the 'image' field
        },
      }));
    };

    // Save the comment with the user's name fetched earlier
    const commentsCollection = collection(
      db,
      `users/${auth.currentUser.uid}/tasks/${taskId}/comments`
    );
    await addDoc(commentsCollection, {
      text: newComment,
      timestamp: new Date(),
      userId: auth.currentUser.uid,
      userName: auth.currentUser.displayName,
    });

    setNewComment("");
  };

  const fetchUserNameAndImage = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);
    const userData = userDoc.data();

    setUserCache((prev) => ({
      ...prev,
      [userId]: {
        name: userData.name,
        image: userData.profileImage, // assuming image URL is stored under the 'image' field
      },
    }));
  };

  const renderCard = (title, content) => (
    <div className="bg-white p-4 rounded shadow-sm">
      <h1 className="font-semibold text-[20px] mb-2">{title}</h1>
      <p>{content}</p>
    </div>
  );

  const handleDeleteComment = async (commentId) => {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      try {
        const commentRef = doc(db, `users/${auth.currentUser.uid}/tasks/${taskId}/comments`, commentId);
        await deleteDoc(commentRef);
        // Optionally, handle state update or show a message to the user
      } catch (error) {
        console.error("Error deleting comment: ", error);
        // Optionally, handle error or show a message to the user
      }
    }
  };

  const handleEditComment = async (commentId, commentText) => {
    // Prompt for a new comment text. You could also use a more complex UI like a modal or an input field next to the comment
    const newText = window.prompt("Edit your comment:", commentText);
    if (newText && newText.trim() !== commentText.trim()) {
      try {
        const commentRef = doc(db, `users/${auth.currentUser.uid}/tasks/${taskId}/comments`, commentId);
        await updateDoc(commentRef, {
          text: newText,
          // If needed, update the timestamp to reflect the edit time
          // timestamp: new Date(),
        });
        // Optionally, handle state update or show a message to the user
      } catch (error) {
        console.error("Error updating comment: ", error);
        // Optionally, handle error or show a message to the user
      }
    }
  };

  return (
    <div className="h-[calc(100vh-5rem)] overflow-auto bg-gray-100 p-6 space-y-6">
      <Link
        to="/tasks"
        className="inline-flex items-center text-virtlaw hover:text-virtlaw/40"
      >
        ← Back to tasks
      </Link>
      <h1 className="text-3xl font-semibold">Task Details</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {task && (
          <>
            {renderCard("Type of Task", task.name)}
            {renderCard("Timeline", task.timeline)}
            {renderCard("Type", task.type)}
            <div className="bg-white p-4 rounded shadow-sm">
              <h3 className="font-semibold text-[20px] mb-2">Files:</h3>
              {task.fileUploads?.length ? (
                task.fileUploads.map((file, index) => (
                  <a
                    key={index}
                    href={file}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center bg-gray-100 p-2 rounded shadow hover:bg-gray-200 transition duration-150 ease-in-out cursor-pointer mb-2"
                  >
                    <DocumentTextIcon className="h-6 w-6 text-virtlaw mr-2" />
                    <span className="text-virtlaw hover:underline">
                      File{index + 1}
                    </span>
                  </a>
                ))
              ) : (
                <p>No Files Attached</p>
              )}
            </div>
          </>
        )}
      </div>

      {task && (
        <div className="mt-6 bg-white p-6 rounded shadow-sm">
          <h3 className="font-semibold text-[20px] mb-4">Summary</h3>
          <p>{task.summary}</p>
        </div>
      )}

      <div className="space-y-4">
        <h2 className="text-2xl font-semibold mb-2">Comments</h2>

        {comments.map((comment) => {
          if (!userCache[comment.userId]) {
            fetchUserNameAndImage(comment.userId);
          }

          return (
            <div key={comment.id} className="border-t pt-4 pb-2">
              <div className="flex justify-between items-start">
                {/* User Image */}
                {userCache[comment.userId]?.image && (
                  <img
                    src={userCache[comment.userId].image}
                    alt={userCache[comment.userId]?.name}
                    className="h-10 w-10 rounded-full mr-4"
                  />
                )}

                <div className="flex-1">
                  {/* User Name and Date */}
                  <div className="space-y-1">
                    <div className="font-medium">
                      {userCache[comment.userId]?.name || "Fetching..."}
                    </div>
                    <span className="text-sm text-gray-400">
                      {formatDateAndTime(comment.timestamp)}
                    </span>
                  </div>

                  {/* Comment */}
                  <p className="mt-2">{comment.text}</p>
                </div>
                {/* Edit and Delete Buttons */}
                {comment.userId === auth.currentUser.uid && (
                  <div className=" flex flex-row bg-gray-200 text-gray-500 border border-gray-300 rounded">
                    <div
                      onClick={() => handleEditComment(comment.id, comment.text)}
                      className="flex items-center px-3 py-1 text-gray-500 border  rounded hover:bg-gray-300 transition duration-300 cursor-pointer"
                    >
                      <AiOutlineEdit className="mr-2" /> Edit
                    </div>
                    <div
                      onClick={() => handleDeleteComment(comment.id)}
                      className="flex items-center px-3 py-1  text-gray-500 border  rounded hover:bg-gray-300 transition duration-300 cursor-pointer"
                    >
                      <AiOutlineDelete className="mr-2" /> Delete
                    </div>
                  </div>
                )}

              </div>
            </div>
          );
        })}


        <div className="mt-4 space-y-4">
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            onKeyPress={(e) => (e.key === 'Enter' && !e.shiftKey) && handleAddComment(e)}
            placeholder="Add a comment..."
            rows={3}
            className="w-full p-3 border outline-none rounded-md focus:border-virtlaw"
          ></textarea>
          <button
            onClick={handleAddComment}
            className="mt-2 px-4 py-2 bg-virtlaw text-white rounded hover:bg-opacity-90"
          >
            Add Comment
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaskDetailsPage;

import React, { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { db, auth } from "../../firebase/firebaseConfig";
import TaskDetailsModal from "./TaskDetailsModal";
import { useNavigate } from "react-router-dom";

const Task = ({ taskId, userId }) => {
  const [task, setTask] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const navigate = useNavigate();

  const navigateToTaskDetails = () => {
    navigate(`/tasks/${taskId}`);
  };

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userId = user.uid;

        const taskDoc = doc(db, `users/${userId}/tasks`, taskId);
        const unsubscribeSnapshot = onSnapshot(taskDoc, (doc) => {
          const data = doc.data();
          if (data) {
            setTask({
              id: doc.id,
              name: data.name,
              summary: data.summary,
              status: data.columnType,
              type: data.type,
              column: data.column,
              due: data.due,
            });
          }
        });

        return () => unsubscribeSnapshot();
      } else {
        console.log("No user is signed in.");
      }
    });

    return () => unsubscribeAuth();
  });


  return (
    <div className="cursor-pointer bg-white min-h-[180px] p-4 rounded-lg shadow-lg flex flex-col space-y-4 hover:border hover:border-gray-300 relative">
      <div className="flex justify-between items-center">
        <div className="flex space-x-2 items-center"> {/* Added items-center */}
          <span
            className={`text-xs px-2 py-0.5 rounded ${task && task.status === "todo"
              ? "text-black bg-gray-300"
              : task && task.status === "inprogress"
                ? "text-black bg-virtlawlight"
                : task && task.status === "done"
                  ? "text-white bg-green-600"
                  : ""
              }`}
          >
            {task && task.status === "todo"
              ? "Submitted"
              : task && task.status === "inprogress"
                ? "WIP"
                : task && task.status === "done"
                  ? "Please Review"
                  : ""}
          </span>

          {task && task.status === "inprogress" && (
            <span
              className="h-2.5 w-2.5 bg-virtlaw rounded-full animate-pulse ml-2"
              title="Work in progress"
            ></span>
          )}
        </div>
        <div className="text-xs text-gray-400">:</div>
      </div>
      <div className="min-h-[80px]">
        <h3 className="text-lg text-gray-900 font-medium">{task?.name}</h3>
      </div>
      <p className="text-gray-600 max-w-[80%] text-sm">{task?.type}</p>

      <button
        onClick={navigateToTaskDetails}
        className="absolute bottom-4 right-4 bg-virtlaw text-white text-xs px-2 py-1 rounded hover:bg-gray-300"
      >
        Open
      </button>
      {isModalOpen && (
        <TaskDetailsModal task={task} toggleModal={toggleModal} />
      )}
    </div>
  );
};

export default Task;


import React from 'react';

const CommunityPage = () => {
  return (
    <div className="bg-white rounded-md shadow-md p-6 max-w-4xl mx-auto my-6 text-center">
      <h2 className="text-3xl font-semibold text-virtlaw mb-4">Virtlaw Community 🌟🤝</h2>
      <p className="text-lg">
        Calling all trailblazers, innovators, and forward-thinkers!
      </p>
      <p className="mt-4">
        Imagine a bustling hub where the brightest minds in the startup cosmos collide. That's Virtlaw Community for you — a soon-to-be-released galaxy where founders and company frontrunners can forge connections, swap stories, and share wisdom. Whether you're a bootstrapping newbie or a seasoned entrepreneur, this will be your space to connect, grow, and spark brilliant collaborations. Prep your best icebreakers; we're about to take networking to stellar new heights!
      </p>
      <div className="mt-6">
        <span className="inline-block bg-virtlaw text-white px-4 py-2 rounded-full text-sm font-semibold tracking-wide">
          Launching Soon!
        </span>
      </div>
    </div>
  );
};

export default CommunityPage;




import React from 'react';

const ConnectPage = () => {
  return (
    <div className="bg-white rounded-md shadow-md p-6 max-w-4xl mx-auto my-6 text-center">
      <h2 className="text-3xl font-semibold text-virtlaw mb-4">Virtlaw Connect 🤖✨</h2>
      <p className="text-lg">
        Hang on to your legal briefs! We're cooking up something that'll redefine your legal toolbelt.
      </p>
      <p className="mt-4">
        Virtlaw Connect is on the horizon, a place where you'll chat with our AI sidekick who's sharp as a tack and eager to help you navigate the twists and turns of legal labyrinths. Whether it's drafting contracts or decoding legalese, help will soon be just a chat away. Stay tuned for an experience that's as easy as pie – legal pie, that is!
      </p>
      <div className="mt-6">
        <span className="inline-block bg-virtlaw text-white px-4 py-2 rounded-full text-sm font-semibold tracking-wide">
          Coming Soon!
        </span>
      </div>
    </div>
  );
};

export default ConnectPage;


// ChatComponent.js
import React, { useEffect, useState, useRef } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { Timestamp, addDoc, collection, doc, getDoc, onSnapshot, orderBy, query } from "firebase/firestore";
import { auth, db } from "../firebase/firebaseConfig";
import { AiOutlineSend } from "react-icons/ai";
import logo from "../assets/logo.png";

const Chat = () => {
  const [user, setUser] = useState({
    name: "",
    username: "",
    profileImage: "",
  });
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef(null);  // Ref for automatic scrolling

  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userId = user.uid;
        const userDoc = doc(db, "users", userId);
        getDoc(userDoc)
          .then((userSnapshot) => {
            if (userSnapshot.exists()) {
              const data = userSnapshot.data();
              setUser({
                name: data.name,
                username: data.username,
                profileImage: data.profileImage,
              });
            } else {
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      } else {
        console.log("No user is signed in.");
      }
    });

    return () => unsubscribe();
  }, [db, auth]);

  useEffect(() => {
    if (!auth.currentUser) return;

    const userMessagesRef = collection(db, `messages/${auth.currentUser.uid}/conversation`);
    const q = query(userMessagesRef, orderBy("createdAt", "asc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedMessages = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setMessages(fetchedMessages);
    });

    return () => unsubscribe();
  }, [db, auth.currentUser]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);  // Scroll to bottom on new messages

  const handleSendMessage = async (event) => {
    event.preventDefault();
    if (newMessage.trim()) {
      const userMessagesRef = collection(db, `messages/${auth.currentUser.uid}/conversation`);
      await addDoc(userMessagesRef, {
        senderId: auth.currentUser.uid,
        text: newMessage,
        createdAt: Timestamp.now()
      });
      setNewMessage('');
    }
  };

  return (
    <div className="bg-gray-100 p-4 h-full flex flex-col">
      <div className="flex items-center border-b-2 pb-4 mb-4">
        <img
          src={logo}
          alt="Admin"
          className="h-10 w-10 rounded-full mr-4"
        />
        <div>
          <div className="font-semibold">Virtlaw Support</div>
          <div className="text-xs text-gray-500">Active now</div>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto no-scrollbar">
        {messages.map(message => (
          <div key={message.id} className={`mb-4 flex ${message.senderId === auth.currentUser.uid ? "justify-end" : ""} items-end`}>
            {message.senderId !== auth.currentUser.uid && <img src={logo} alt="Admin" className="h-8 w-8 rounded-full mr-3" />}
            <div className={`bg-${message.senderId === auth.currentUser.uid ? "virtlaw" : "white"} text-${message.senderId === auth.currentUser.uid ? "white" : "black"} p-3 rounded-tl-3xl rounded-bl-3xl rounded-br-3xl shadow-md max-w-md`}>
              {message.text}
              <div className="text-xs text-gray-500 mt-2">
                {formatTimestamp(message.createdAt)}
              </div>
            </div>
            {message.senderId === auth.currentUser.uid && <img src={user.profileImage} alt="User" className="h-8 w-8 rounded-full ml-3" />}
          </div>
        ))}
        <div ref={messagesEndRef} /> {/* Empty div for scrolling */}
      </div>

      <form onSubmit={handleSendMessage} className="flex items-center border-t border-gray-200 pt-4">
        <div className="flex-1 mr-4">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
            className="w-full p-3 rounded-3xl border border-gray-300 outline-none focus:outline-none"
          />
        </div>
        <button type="submit" className='bg-virtlawlight cursor-pointer p-2 rounded-2xl'>
          <AiOutlineSend size={20} className='text-black' />
        </button>
      </form>
    </div>
  );
}

export default Chat;

// UserContext.js
import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext(null);

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [isAdmin, setIsAdmin] = useState(false);

    return (
        <UserContext.Provider value={{ isAdmin, setIsAdmin }}>
            {children}
        </UserContext.Provider>
    );
};

import React, { useState } from "react";
import { db } from "../../../firebase/firebaseConfig";
import { addDoc, collection, Timestamp } from "firebase/firestore"; // make sure to import Timestamp
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

export default function CorporateGovernance({ userId, onSuccess }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState("");
  const [optionDescription, setOptionDescription] = useState("");
  const [turnaroundTime, setTurnaroundTime] = useState("");
  const [fileURLs, setFileURLs] = useState([]);


  const handleChange = (e) => {
    const selected = options.find((option) => option.value === e.target.value);
    setSelectedOption(selected.value);
    setOptionDescription(selected.description);
  };

  const options = [
    {
      value: "Corporate Document Review",
      label: "Corporate Document Review",
      description:
        "Request a review and potential update of your company's corporate documents, including bylaws, company agreements, and corporate resolutions, to ensure they align with current regulations and best practices.",
    },
    {
      value: "Board Meeting Minutes Preparation",
      label: "Board Meeting Minutes Preparation",
      description:
        "Task Virtlaw with preparing detailed and compliant minutes for your upcoming board of directors' meetings.",
    },
    {
      value: "Shareholder Agreement Drafting",
      label: "Shareholder Agreement Drafting",
      description:
        "Request the creation of a shareholder agreement that outlines the rights and responsibilities of shareholders in your corporation.",
    },
    {
      value: "Director and Officer Liability Advice",
      label: "Director and Officer Liability Advice",
      description:
        "Seek legal written advice on the responsibilities and potential liabilities of directors and officers in your company.",
    },
    {
      value: "Corporate Governance Audit",
      label: "Corporate Governance Audit",
      description:
        "Request an audit of your corporate governance practices to ensure compliance with legal and regulatory requirements.",
    },
    {
      value: "Corporate Records Management",
      label: "Corporate Records Management",
      description:
        "Seek assistance in organizing and maintaining your company's corporate records, resolutions, contracts and documents.",
    },
    {
      value: "Corporate Governance Reporting",
      label: "Corporate Governance Reporting",
      description:
        "Ensure compliance with regulatory reporting requirements related to corporate governance, such as the Corporate Transparency Act.",
    },
    {
      value: "Other",
      label: "Other",
      description:
        "Please choose this option if you don’t see your specific Task in the above list. The above list are just examples of some Corporate Governane Tasks Virtlaw can assist you with.",
    },
  ];

  const [summary, setSummary] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [files, setFiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [step2Completed, setStep2Completed] = useState(false);
  const [step3Completed, setStep3Completed] = useState(false);
  const [step4Completed, setStep4Completed] = useState(false);

  const handleFileUpload = async (file) => {
    const storage = getStorage();
    const storageRef = ref(storage, "uploads/" + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);

    const renderUploadedFiles = () => {
      return fileURLs.map((url, idx) => (
        <div key={idx}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            View File {idx + 1}
          </a>
        </div>
      ));
    };

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => { },
        (error) => reject(error),
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

  // set task timeline
  const handleTimelineSelect = (hours) => {
    setTurnaroundTime(hours);
    setIsSelected(true); // you can still use this to change button styles or other logic
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let fileURLs = [];

    // Handle file uploads
    if (files) {
      for (const file of files) {
        const url = await handleFileUpload(file);
        fileURLs.push(url);
      }
    }

    if (!step2Completed || !step3Completed || !step4Completed) {
      // Alert the user about the incomplete steps
      alert('Please complete all the required steps before submitting.');
      return;
    }

    // Get the current date and time
    const now = new Date();

    // Add 48 hours (2 * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds) to the current time
    const dueDate = new Date(now.getTime() + 2 * 24 * 60 * 60 * 1000);


    const task = {
      columnType: "todo",
      name: "Corporate Governance Task",
      taskId: "udb1CSJ1djUpCySwhmL2",
      template: "cgt",
      summary: summary,
      type: selectedOption,
      fileUploads: fileURLs,
      timeline: turnaroundTime,
      // Add the due date as a Timestamp
      due: Timestamp.fromDate(dueDate),
      // Add the submitted date as a Timestamp
      submittedDate: Timestamp.fromDate(now),
    };

    try {
      const docRef = await addDoc(
        collection(db, `users/${userId}/tasks`),
        task
      );
      console.log("Document written with ID: ", docRef.id);
      setLoading(false);
      onSuccess && onSuccess();
    } catch (error) {
      setLoading(false);
      console.error("Error adding document: ", error);
    }
  };

  const isNextButtonDisabled = () => {
    switch (currentStep) {
      case 1:
        // Assuming there's no condition to disable the "Next" button in step 1
        return false;
      case 2:
        // Disable "Next" button if the summary is less than 10 characters
        return summary.trim().length < 10;
      case 3:
        // Disable "Next" button if no task has been selected
        return !selectedOption;
      case 4:
        // Disable "Next" button if no priority has been selected
        return !turnaroundTime;
      default:
        return false;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 w-full h-full  lg:h-[500px] lg:w-[800px] mt-10">
      {/* Tabs for Steps */}
      <div className="flex justify-between items-center w-full bg-gray-100 rounded-t-md lg:p-2 mb-6">
        {["Share Documentation", "Provide Summary", "Choose Task", "Select Priority", "Submit Task"].map((label, idx) => (
          <span
            className={`cursor-pointer text-xs lg:text-lg p-2 rounded ${currentStep === idx + 1
              ? "bg-virtlaw text-white"
              : currentStep > idx + 1
                ? "bg-virtlaw text-virtlawlight"
                : "text-gray-500"
              }`}
            onClick={() => setCurrentStep(idx + 1)}
          >
            {label}
          </span>
        ))}
      </div>

      <div className="">
        {currentStep === 1 && (
          <div className="mb-4 p-4 mt-6 bg-gray-100 rounded-md shadow-md">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">
              Step 1: Share all relevant documentation
            </h3>
            <p>
              Share the relevant documents that our legal team will need to
              efficiently handle your Task.
            </p>
            <div className="mb-2">
              <label>
                <input
                  type="file"
                  name="files"
                  onChange={(e) => setFiles(e.target.files)}
                  multiple
                />
              </label>
            </div>
            {files && Array.from(files).map((file, idx) => (
              <div key={idx}>
                {file.name}
              </div>
            ))}
          </div>
        )}


        {currentStep === 2 && (
          <div className="mb-8 p-4 mt-6 bg-gray-100 rounded-md shadow-md">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">
              Step 2: Provide a summary of the Task being requested
            </h3>
            <p className="text-gray-600 mb-4">
              Please provide a summary of the nature of the Task being
              requested. Feel free to share any other relevant information that
              would help our legal team handle your Task more efficiently.
            </p>
            <textarea
              name="summary"
              value={summary}
              placeholder="Enter your summary"
              className="w-full px-2 pt-1 pb-8 border border-gray-300 rounded-lg outline-none"
              rows="4"
              onChange={(e) => {
                setSummary(e.target.value);
                setStep2Completed(e.target.value.trim() !== '');
              }}
              required
            ></textarea>
          </div>
        )}


        {currentStep === 3 && (
          <div className="my-4 p-4 mt-6 bg-gray-100 rounded-md shadow-md">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">
              Step 3: Choose one of our predetermined Corporate Governance
              Tasks
            </h3>

            <div className="space-y-4">
              <div className="bg-gray-100 p-6 rounded-md shadow-md">
                <label className="block text-lg font-semibold text-gray-700">
                  Select a Corporate Governance Tasks
                  <select
                    name="service"
                    value={selectedOption} // Added this to set the value of the select to the value from state
                    onChange={(e) => {
                      handleChange(e);
                      setStep3Completed(e.target.value.trim() !== '');
                    }}
                    required
                    className="block w-full mt-2 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-2 focus:ring-gray-300"
                  >
                    <option value="" disabled>
                      Select an option...
                    </option>
                    {options.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </label>
                <p className="mt-2 text-gray-500">{optionDescription}</p>
              </div>
            </div>
          </div>
        )}
        {currentStep === 4 && (
          <div className="my-4 p-4 mt-6 bg-gray-100 rounded-md shadow-md">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">
              Step 4: Select the priority of this task
            </h3>
            <div className="flex flex-row space-x-4">
              <button
                type="button"
                onClick={() => {
                  handleTimelineSelect("72h");
                  setStep4Completed(true);
                }} className={`py-2.5 px-5 rounded mb-5 ${isSelected ? 'bg-virtlawlight text-black' : 'bg-virtlaw text-white'}`}

              >
                72 Hours
              </button>
              <div
                className=""
                style={{ position: "relative", display: "inline-block" }}
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
              >
                <button
                  type="button" // setting the type to button to prevent form submission in a form context
                  style={{
                    backgroundColor: "#bdc3c7",
                    color: "#ffffff",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    cursor: "not-allowed",
                  }}
                  disabled
                >
                  Priority: 24 Hours
                </button>
              </div>
              {isHovered && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "100%",
                    left: "50%",
                    width: 200,
                    transform: "translateX(-50%)",
                    marginBottom: "10px",
                    opacity: "1",
                    backgroundColor: "#000000",
                    color: "#ffffff",
                    padding: "10px",
                    borderRadius: "5px",
                    visibility: "visible",
                    transition: "opacity 0.3s",
                  }}
                >
                  Please, upgrade your package to enjoy Priority
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {/* Here's the Next button to go to the next step */}
      <div className="flex justify-between my-4">
        {currentStep > 1 && currentStep < 5 && (
          <button
            type="button"
            className="bg-virtlaw rounded-md text-white px-4 py-2 text-center"
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            Back
          </button>
        )}

        {currentStep < 5 && (
          <button
            type="button"
            className={`bg-virtlaw rounded-md text-white px-4 py-2 text-center ${isNextButtonDisabled() ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={() => {
              if (!isNextButtonDisabled()) {
                setCurrentStep(currentStep + 1);
              }
            }}
            disabled={isNextButtonDisabled()}
          >
            Next
          </button>
        )}
      </div>
      {/* Submit button will only be visible on the last step */}
      {currentStep === 5 && (
        <div className="flex flex-col justify-between space-y-6 ">
          <h3 className="text-xl font-semibold text-gray-700 mb-4">
            Review & Submit your Virtlaw Task
          </h3>

          {/* Summary Section */}
          <div className="bg-white p-4 rounded-md shadow-md space-y-4">
            <h4 className="text-lg font-medium text-gray-700">Summary:</h4>

            {/* Files */}
            <div className="flex items-center space-x-2">
              <span className="font-semibold text-gray-600">Files:</span>
              {files && Array.from(files).length > 0 ? (
                Array.from(files).map((file, idx) => (
                  <span key={idx} className="text-gray-500">{file.name}</span>
                ))
              ) : (
                <span className="text-gray-500">No files selected</span>
              )}
            </div>

            {/* Task summary */}
            <div>
              <span className="font-semibold text-gray-600">Summary: </span>
              <span className="text-gray-500">{summary || "No summary provided"}</span>
            </div>

            {/* Type of task */}
            <div>
              <span className="font-semibold text-gray-600">Type of Task: </span>
              <span className="text-gray-500">{selectedOption || "Not selected"}</span>
            </div>

            {/* Priority */}
            <div>
              <span className="font-semibold text-gray-600">Priority: </span>
              <span className="text-gray-500">{isSelected ? "72 Hours" : "Not set"}</span>
            </div>
          </div>

          <div className="flex flex-row justify-between">
            <button
              type="button"
              className="bg-virtlaw rounded-md text-white px-4 py-2 text-center"
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              Back
            </button>

            <button
              type="submit"
              className="bg-virtlaw rounded-md text-white px-4 py-2 text-center"
            >
              {loading ? "Creating..." : "Submit Task"}
            </button>
          </div>
        </div>
      )}
    </form>
  );
}

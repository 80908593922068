import { faCheckCircle, faClock, faFile, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';

const LazyNDAContractComponent = React.lazy(() => import('./CorporateGovernanceContractTemplates/NDAContractComponent'));
// ... similar imports for other templates

// This object should be defined to map template IDs to the dynamic component.
const templateComponents = {
    1: LazyNDAContractComponent,
    // ... map other template IDs to their respective lazy-loaded components
};

const templates = [
    { id: 1, name: "NDA Contract Template", description: "This is a sample NDA contract template." },
    // ... other templates
];

function CorporateGovernanceTemplates() {
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const navigate = useNavigate();
    const cardData = [
        { title: "Used Templates", value: 1, color: "bg-virtlaw/80", icon: faList },
        {
            title: "Remaining Templates",
            value: 4,
            color: "bg-virtlaw/80",
            icon: faCheckCircle,
        },
    ];

    // Access the component based on the selected template ID.
    const TemplateForm = templateComponents[selectedTemplate] || null;

    return (
        <div>
            <div>
                <button
                    className="text-lg text-gray-500 hover:text-gray-700"
                    onClick={() => navigate('/templates')}

                >
                    ← Select a Different Category
                </button>
            </div>
            <div className="grid mt-10 grid-cols-3 gap-4">

                {templates.map(template => (
                    <div key={template.id} className="p-4 border rounded shadow">
                        <h2 className="text-lg font-bold">{template.name}</h2>
                        <p>{template.description}</p>
                        <button
                            className="mt-2 bg-virtlaw hover:bg-virtlaw/80 text-white font-bold py-2 px-4 rounded"
                            onClick={() => setSelectedTemplate(template.id)}
                        >
                            Fill Template
                        </button>
                    </div>
                ))}
            </div>
            {TemplateForm && (
                <div className="mt-8">
                    <Suspense fallback={<div>Loading...</div>}>
                        <TemplateForm />
                    </Suspense>
                </div>
            )}
        </div>
    );
}

export default CorporateGovernanceTemplates
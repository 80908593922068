import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "../../../firebase/UserContext"; // Adjust the path to your UserContext
import {
  HomeIcon,
  ChatBubbleLeftEllipsisIcon,
  InboxIcon,
  RectangleStackIcon,
  WifiIcon,
  GlobeAmericasIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";

export const Sidebar = () => {
  const { isAdmin } = useUser();
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const adminNavItems = [
    { name: "Dashboard", to: "/dashboard-admin", icon: HomeIcon },
    { name: "Tasks", to: "/tasks-admin", icon: RectangleStackIcon },
    { name: "Chats", to: "/chat-admin", icon: ChatBubbleLeftEllipsisIcon },
    // ... other admin-specific navigation items ...
  ];

  const userNavItems = [
    { name: "Dashboard", to: "/dashboard", icon: HomeIcon },
    {
      name: "Virtlaw Tasks",
      icon: RectangleStackIcon,
      subItems: [
        { name: "Active Tasks", to: "/tasks" },
        // { name: "Historical Tasks", to: "/tasks/historical" },
      ],
    },
    { name: "Virtlaw Templates", to: "/templates", icon: InboxIcon },
    { name: "Virtlaw Files", to: "/files", icon: RectangleStackIcon },
    { name: "Virtlaw Connect", to: "/connect", icon: WifiIcon },
    { name: "Virtlaw Chat", to: "/chat", icon: ChatBubbleLeftEllipsisIcon },
    { name: "Virtlaw Community", to: "/community", icon: GlobeAmericasIcon },
    // ... other user-specific navigation items ...
  ];

  // Default to userNavItems unless isAdmin is explicitly true
  const navItems = isAdmin === true ? adminNavItems : userNavItems;

  return (
    <div className="bg-white text-black h-screen w-64 p-4 border-r">
      <div className="mb-8">
        {/* Your logo here */}
        <a href="/">
          <img
            src="/assets/images/logo/logo.png"
            alt="Logo"
            className="mb-10 max-w-[200px]"
          />
        </a>
      </div>
      <ul>
        {navItems.map(({ name, to, icon: Icon, subItems }) => (
          <div key={to}>
            <li
              className={`mb-4 space-x-4 p-2 rounded-md flex items-center justify-between ${location.pathname.startsWith(to) ? "bg-virtlaw text-white" : ""
                }`}
              onClick={() => subItems && setDropdownOpen(!dropdownOpen)}
            >
              <div className="flex items-center space-x-4">
                <Icon className="w-[22px]" />
                <Link to={to} className="text-[18px]">
                  {name}
                </Link>
              </div>
              {subItems && (
                <ChevronDownIcon
                  className={`transform cursor-pointer transition-transform ${dropdownOpen ? "rotate-180" : ""
                    } w-5 h-5`}
                />
              )}
            </li>
            {subItems && dropdownOpen && (
              <ul className="ml-8">
                {subItems.map(({ name, to }) => (
                  <li
                    key={to}
                    className={`mb-2 ${location.pathname === to ? "bg-virtlaw rounded-md px-2 py-1 text-white" : ""
                      }`}
                  >
                    <Link to={to} className="text-[16px] block py-1 px-2 rounded-md">
                      {name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;

import React, { useState } from "react";
import { Sidebar } from "../components/partials/sidebar/Sidebar";
import { TopNavbar } from "../components/partials/navbar/TopNavbar";

export const RootLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex overflow-hidden h-screen">
      {/* Sidebar - Hidden on smaller screens, initially closed */}
      <div
        className={`lg:w-64 lg:block ${isSidebarOpen ? "w-64" : "w-0"
          } overflow-y-auto transition-all duration-300`}
      >
        <Sidebar />
      </div>

      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex justify-between items-center lg:justify-end p-4">
          {/* Hamburger Menu Button - Visible on smaller screens */}
          <button
            onClick={toggleSidebar}
            className="text-gray-600 focus:outline-none lg:hidden"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
          {/* Top Navbar - Always visible */}
          <TopNavbar />
        </div>
        <div className="flex-1 overflow-y-auto">{children}</div>
      </div>
    </div>
  );
};


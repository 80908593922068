
import React, { useState, useEffect, useRef } from 'react';
import { auth, db } from '../firebase/firebaseConfig';
import { collection, addDoc, query, onSnapshot } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { FaFilePdf, FaFileWord, FaImage } from 'react-icons/fa';

export default function Files() {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const user = auth.currentUser;
  const storage = getStorage();
  const fileInputRef = useRef();

  const getFileIcon = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return <FaFilePdf className="text-virtlawlight" size={30} />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <FaImage className="text-virtlawlight" size={30} />;
      case 'doc':
      case 'docx':
        return <FaFileWord className="text-virtlawlight" size={30} />;
      default:
        return <FaFileWord className="text-virtlawlight" size={30} />;
    }
  };

  useEffect(() => {
    if (user) {
      const filesCollectionRef = collection(db, `users/${user.uid}/files`);
      const q = query(filesCollectionRef);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const filesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUploadedFiles(filesData);
      });

      return () => unsubscribe(); // Detach listener when component unmounts
    }
  }, [user]);

  const handleUpload = async () => {
    if (files.length === 0 || !user) return;
    setUploading(true);

    for (const file of files) {
      const storageRef = ref(storage, `users/${user.uid}/files/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      try {
        await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              // Handle upload progress
            },
            (error) => {
              reject(error);
            },
            async () => {
              // Handle successful uploads
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              await addDoc(collection(db, `users/${user.uid}/files`), {
                name: file.name,
                url: downloadURL,
                uploadedAt: new Date(),
              });
              resolve();
            }
          );
        });
      } catch (error) {
        console.error('File upload error:', error);
      }
    }

    // Reset the file input and uploading state
    fileInputRef.current.value = '';
    setFiles([]);
    setUploading(false);
  };

  return (
    <div className='p-6 max-w-5xl mx-auto bg-white rounded-md  mt-6'>
      <div className="bg-virtlaw rounded-md p-4 text-white mb-6">
        <h3 className="text-lg font-semibold">Just a Nifty Heads-up! 🛠️</h3>
        <p>
          Our full-fledged Contract Management System is currently under construction – it's going to be slick, smart, and super easy to use! In the meantime, our dedicated team of legal beagles is all hands on deck to help you organize your contracts and set up the necessary alerts. Think of us as your personal contract concierges, ensuring everything's in tip-top shape. Hang tight, and we'll have those digital drawers sorted for you in a jiffy!
        </p>
      </div>
      {/* Optional: add the disclaimer message here */}

      <input
        ref={fileInputRef}
        type='file'
        multiple
        onChange={(e) => setFiles(e.target.files)}
        className='mb-4'
      />
      <button
        onClick={handleUpload}
        disabled={uploading}
        className={`bg-virtlaw hover:bg-virtlaw/90 text-white py-2 px-4 rounded ${uploading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
      >
        {uploading ? 'Uploading...' : 'Upload Files'}
      </button>

      <div className='mt-4'>
        <h3 className='text-lg font-semibold text-gray-700 mb-4'>Your Files</h3>
        <ul className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
          {uploadedFiles.map((file) => (
            <li key={file.id} className='flex flex-col items-left justify-between p-4 bg-gray-200 rounded-md shadow-sm h-36 '>
              <div className="flex items-center justify-center w-12 h-12 bg-gray-400 rounded-full">
                {getFileIcon(file.name)}
              </div>
              <div className="flex flex-col items-left justify-center w-full mt-2">
                <p className="text-center truncate w-full">{file.name}</p>
                <a
                  href={file.url}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-blue-500 hover:text-blue-700 text-sm mt-2'
                >
                  Download Your files
                </a>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

import React, { createContext, useContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase/firebaseConfig";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { RootLayout } from "../layouts/RootLayout";

import DashboardPage from "../pages/Dashboard";
import LoginPage from "../pages/Login";
import ChatPage from "../pages/Chat";
import TemplatesPage from "../pages/Templates";
import TaskBoard from "../pages/Task/TaskBoard";
import Yourteam from "../pages/Yourteam";
import ConnectPage from "../pages/Connect";
import CommunityPage from "../pages/Community";
import DocumentationPage from "../pages/Documentation";
import Loading from "../components/partials/loading/loading";
import TaskDetailsPage from "../pages/Task/TaskDetails";
import CorporateGovernanceTemplates from "../pages/templates/CorporateGovernance/CorporateGovernanceTemplates";
import Files from "../pages/Files";
import { useUser } from "../firebase/UserContext";
import AdminDashboard from "../pages/AdminDashboard";
import AdminTasks from "../pages/AdminTasks";
import AdminChat from "../pages/AdminChat";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};


const AppRoutes = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const { isAdmin } = useUser(); // Use isAdmin from UserContext

  if (isLoading) {
    return <Loading />;
  }

  const adminRoute = (Component) => (
    isAuthenticated && isAdmin ? (
      <RootLayout>
        <Component />
      </RootLayout>
    ) : (
      <Navigate to="/dashboard" />
    )
  );




  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/dashboard"
          element={
            isAuthenticated ? (
              <RootLayout>
                <DashboardPage />
              </RootLayout>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/chat"
          element={
            isAuthenticated ? (
              <RootLayout>
                <ChatPage />
              </RootLayout>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/templates" element={
          isAuthenticated ? (
            <RootLayout>
              <TemplatesPage />
            </RootLayout>
          ) : (
            <Navigate to="/login" />
          )
        }>
        </Route>
        <Route
          path="/team"
          element={
            isAuthenticated ? (
              <RootLayout>
                <Yourteam />
              </RootLayout>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/tasks"
          element={
            isAuthenticated ? (
              <RootLayout>
                <TaskBoard />
              </RootLayout>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/tasks/:taskId"
          element={
            isAuthenticated ? (
              <RootLayout>
                <TaskDetailsPage />
              </RootLayout>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/connect"
          element={
            isAuthenticated ? (
              <RootLayout>
                <ConnectPage />
              </RootLayout>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/community"
          element={
            isAuthenticated ? (
              <RootLayout>
                <CommunityPage />
              </RootLayout>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/files"
          element={
            isAuthenticated ? (
              <RootLayout>
                <Files />
              </RootLayout>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/templates/corporate-governance-templates"
          element={
            isAuthenticated ? (
              <RootLayout>
                <CorporateGovernanceTemplates />
              </RootLayout>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/dashboard-admin" element={adminRoute(AdminDashboard)} />
        <Route path="/tasks-admin" element={adminRoute(AdminTasks)} />
        <Route path="/chat-admin" element={adminRoute(AdminChat)} />
        <Route
          path="*"
          element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} />}
        />
      </Routes>
    </Router>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  );
};

export default App;

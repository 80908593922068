import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./tailwind.css";
// import "./scss/app.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />); // Renders the App component with UserProvider

reportWebVitals();

// TaskModal.js
import React, { useState } from "react";
import CorporateGovernance from "./templates/CorporateGovernance";
import BusinessAndContractLaw from "./templates/BusinessAndContractLaw";
import LaborAndEmployment from "./templates/LaborAndEmploymentLaw";
import IntellectualProperty from "./templates/IntellectualProperty";
import MergersAndAcquisitions from "./templates/MergersAndAcquisitions";
import BusinessLegalAdvisory from "./templates/BusinessLegalAdvisory";
import OtherLegal from "./templates/OtherLegal";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { GiOfficeChair, } from "react-icons/gi"
import { BiSolidBusiness, BiSolidFactory } from "react-icons/bi"
import { LiaCopyrightSolid } from "react-icons/lia"
import { MdBusinessCenter } from "react-icons/md"
import { AiFillAlert, AiOutlineClose } from "react-icons/ai"


export default function TaskModal({ isOpen, onClose, userId }) {
  const [template, setTemplate] = useState("");
  const cardStyles =
    "flex flex-col cursor-pointer h-[200px] lg:max-w-[33%] sm:max-w-[50%] items-start px-4 justify-center space-y-2 bg-white text-black rounded-lg shadow-md transition duration-300 ease-in-out transform lg:hover:-translate-y-1 lg:hover:scale-105";
  const titleStyles = "text-md font-semibold mb-2";

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setTemplate("");
  };

  const handleSelectTemplate = (templateType) => {
    setTemplate(templateType);
  };

  const handleGoBack = () => {
    setTemplate("");
  };

  return (
    isOpen && (
      <div className="fixed  inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center z-50">
        <div className="relative bg-white p-4 rounded-lg w-full h-full lg:h-auto lg:w-[70%] mx-auto  lg:mt-[120px] lg:mb-[100px] overflow-y-auto my-4">
          <div className="absolute top-0 left-0 right-0 flex justify-between p-4">
            {template && ( // Only render the Go Back button if a template is selected
              <button
                className="text-lg text-gray-500 hover:text-gray-700"
                onClick={handleGoBack}
              >
                ← Select a Different Task
              </button>
            )}
            <AiOutlineClose onClick={handleClose} size={25} className="cursor-pointer text-gray-500 hover:text-gray-700" />
            {/* <button
              className="text-lg text-gray-500 hover:text-gray-700"
              onClick={handleClose}
            > 
            </button> */}
          </div>
          {/* Modal Content */}
          <div className="flex mt-6 flex-col items-center justify-center space-y-4">
            {!template ? (
              <>
                <h2 className="text-2xl font-semibold mb-4">
                  Please Select A Task's Template
                </h2>
                <div className="flex-col hidden lg:flex">
                  <div className="flex flex-row space-x-4">
                    {/* corporate governance */}
                    <div
                      onClick={() => handleSelectTemplate("corporategovernance")}
                      className={cardStyles}
                    >
                      <div className="flex flex-row justify-start space-x-3 items-center">
                        <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
                          <GiOfficeChair className="text-2xl text-virtlawlight" />
                        </div>
                        <h3 className={titleStyles}>Corporate Governance</h3>
                      </div>
                      <p className="text-sm text-left mb-4">
                        Set up your company profile, add your company logo, and more
                      </p>
                      <div className="mt-4">
                        <button className="text-virtlaw text-sm mt-4 hover:underline">Select Task</button>
                      </div>
                    </div>

                    {/* business and contract  */}
                    <div
                      onClick={() => handleSelectTemplate("businessandcontractlaw")}
                      className={cardStyles}
                    >
                      <div className="flex flex-row justify-start space-x-3 items-center">
                        <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
                          <BiSolidBusiness className="text-virtlawlight text-2xl" />
                        </div>
                        <h3 className={titleStyles}> Business and Contract Law</h3>
                      </div>
                      <p className="text-sm text-left mb-4">
                        Set up your company profile, add your company logo, and more
                      </p>
                      <div className="mt-4">
                        <button className="text-virtlaw text-sm mt-4 hover:underline">Select Task</button>
                      </div>
                    </div>

                    {/* labor and employment */}

                    <div
                      onClick={() => handleSelectTemplate("laborandemployment")}
                      className={cardStyles}
                    >
                      <div className="flex flex-row justify-start space-x-3 items-center">
                        <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
                          <BiSolidFactory className="text-virtlawlight text-2xl" />
                        </div>
                        <h3 className={titleStyles}> Labor and Employment Law</h3>
                      </div>
                      <p className="text-sm text-left mb-4">
                        Set up your company profile, add your company logo, and more
                      </p>
                      <div className="mt-4">
                        <button className="text-virtlaw text-sm mt-4 hover:underline">Select Task</button>
                      </div>
                    </div>
                  </div>

                  {/* intellectual property  */}
                  <div className="flex flex-row space-x-4 mt-4">
                    <div
                      onClick={() => handleSelectTemplate("intellectualproperty")}
                      className={cardStyles}
                    >
                      <div className="flex flex-row justify-start space-x-3 items-center">
                        <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
                          <LiaCopyrightSolid className="text-virtlawlight text-2xl" />
                        </div>
                        <h3 className={titleStyles}>Intellectual Property</h3>
                      </div>
                      <p className="text-sm text-left mb-4">
                        Set up your company profile, add your company logo, and more
                      </p>
                      <div className="mt-4">
                        <button className="text-virtlaw text-sm mt-4 hover:underline">Select Task</button>
                      </div>
                    </div>
                    {/*  mergersandacquisitions */}
                    <div
                      onClick={() => handleSelectTemplate("mergersandacquisitions")}
                      className={cardStyles}
                    >
                      <div className="flex flex-row justify-start space-x-3 items-center">
                        <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
                          <FaRegMoneyBillAlt className="text-virtlawlight text-2xl" />
                        </div>
                        <h3 className={titleStyles}>Mergers And Acquisitions</h3>
                      </div>
                      <p className="text-sm text-left mb-4">
                        Set up your company profile, add your company logo, and more
                      </p>
                      <div className="mt-4">
                        <button className="text-virtlaw text-sm mt-4 hover:underline">Select Task</button>
                      </div>
                    </div>
                    {/* mergers and acquisitions */}
                    <div
                      onClick={() => handleSelectTemplate("businesslegaladvisory")}
                      className={cardStyles}
                    >
                      <div className="flex flex-row justify-start space-x-3 items-center">
                        <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
                          <MdBusinessCenter className="text-virtlawlight text-2xl" />
                        </div>
                        <h3 className={titleStyles}>Business Legal Advisory</h3>
                      </div>
                      <p className="text-sm text-left mb-4">
                        Set up your company profile, add your company logo, and more
                      </p>
                      <div className="mt-4">
                        <button className="text-virtlaw text-sm mt-4 hover:underline">Select Task</button>
                      </div>
                    </div>
                  </div>
                  {/* otherlegal */}
                  <div className="flex mt-4 items-center justify-center">
                    <div
                      onClick={() => handleSelectTemplate("otherlegal")}
                      className={cardStyles}
                    >
                      <div className="flex flex-row justify-start space-x-3 items-center">
                        <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
                          <AiFillAlert className="text-virtlawlight text-2xl" />
                        </div>
                        <h3 className={titleStyles}>Other Legal Tasks</h3>
                      </div>
                      <p className="text-sm text-left mb-4">
                        Set up your company profile, add your company logo, and more
                      </p>
                      <div className="mt-4">
                        <button className="text-virtlaw text-sm mt-4 hover:underline">Select Task</button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* mobile */}
                <div className="flex-col lg:hidden flex">
                  <div className="grid grid-cols-1 space-y-2 ">
                    {/* corporate governance */}
                    <div
                      onClick={() => handleSelectTemplate("corporategovernance")}
                      className={cardStyles}
                    >
                      <div className="flex flex-row justify-start space-x-3 items-center">
                        <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
                          <GiOfficeChair className="text-2xl text-virtlawlight" />
                        </div>
                        <h3 className={titleStyles}>Corporate Governance</h3>
                      </div>
                      <p className="text-sm text-left mb-4">
                        Set up your company profile, add your company logo, and more
                      </p>
                      <div className="mt-4">
                        <button className="text-virtlaw text-sm mt-4 hover:underline">Select Task</button>
                      </div>
                    </div>

                    {/* business and contract  */}
                    <div
                      onClick={() => handleSelectTemplate("businessandcontractlaw")}
                      className={cardStyles}
                    >
                      <div className="flex flex-row justify-start space-x-3 items-center">
                        <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
                          <BiSolidBusiness className="text-virtlawlight text-2xl" />
                        </div>
                        <h3 className={titleStyles}> Business and Contract Law</h3>
                      </div>
                      <p className="text-sm text-left mb-4">
                        Set up your company profile, add your company logo, and more
                      </p>
                      <div className="mt-4">
                        <button className="text-virtlaw text-sm mt-4 hover:underline">Select Task</button>
                      </div>
                    </div>

                    {/* labor and employment */}

                    <div
                      onClick={() => handleSelectTemplate("laborandemployment")}
                      className={cardStyles}
                    >
                      <div className="flex flex-row justify-start space-x-3 items-center">
                        <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
                          <BiSolidFactory className="text-virtlawlight text-2xl" />
                        </div>
                        <h3 className={titleStyles}> Labor and Employment Law</h3>
                      </div>
                      <p className="text-sm text-left mb-4">
                        Set up your company profile, add your company logo, and more
                      </p>
                      <div className="mt-4">
                        <button className="text-virtlaw text-sm mt-4 hover:underline">Select Task</button>
                      </div>
                    </div>


                    {/* intellectual property  */}

                    <div
                      onClick={() => handleSelectTemplate("intellectualproperty")}
                      className={cardStyles}
                    >
                      <div className="flex flex-row justify-start space-x-3 items-center">
                        <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
                          <LiaCopyrightSolid className="text-virtlawlight text-2xl" />
                        </div>
                        <h3 className={titleStyles}>Intellectual Property</h3>
                      </div>
                      <p className="text-sm text-left mb-4">
                        Set up your company profile, add your company logo, and more
                      </p>
                      <div className="mt-4">
                        <button className="text-virtlaw text-sm mt-4 hover:underline">Select Task</button>
                      </div>
                    </div>
                    {/*  mergersandacquisitions */}
                    <div
                      onClick={() => handleSelectTemplate("mergersandacquisitions")}
                      className={cardStyles}
                    >
                      <div className="flex flex-row justify-start space-x-3 items-center">
                        <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
                          <FaRegMoneyBillAlt className="text-virtlawlight text-2xl" />
                        </div>
                        <h3 className={titleStyles}>Mergers And Acquisitions</h3>
                      </div>
                      <p className="text-sm text-left mb-4">
                        Set up your company profile, add your company logo, and more
                      </p>
                      <div className="mt-4">
                        <button className="text-virtlaw text-sm mt-4 hover:underline">Select Task</button>
                      </div>
                    </div>
                    {/* mergers and acquisitions */}
                    <div
                      onClick={() => handleSelectTemplate("businesslegaladvisory")}
                      className={cardStyles}
                    >
                      <div className="flex flex-row justify-start space-x-3 items-center">
                        <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
                          <MdBusinessCenter className="text-virtlawlight text-2xl" />
                        </div>
                        <h3 className={titleStyles}>Business Legal Advisory</h3>
                      </div>
                      <p className="text-sm text-left mb-4">
                        Set up your company profile, add your company logo, and more
                      </p>
                      <div className="mt-4">
                        <button className="text-virtlaw text-sm mt-4 hover:underline">Select Task</button>
                      </div>
                    </div>
                    {/* otherlegal */}

                    <div
                      onClick={() => handleSelectTemplate("otherlegal")}
                      className={cardStyles}
                    >
                      <div className="flex flex-row justify-start space-x-3 items-center">
                        <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
                          <AiFillAlert className="text-virtlawlight text-2xl" />
                        </div>
                        <h3 className={titleStyles}>Other Legal Tasks</h3>
                      </div>
                      <p className="text-sm text-left mb-4">
                        Set up your company profile, add your company logo, and more
                      </p>
                      <div className="mt-4">
                        <button className="text-virtlaw text-sm mt-4 hover:underline">Select Task</button>
                      </div>
                    </div>
                  </div>
                </div>

              </>
            ) : (
              <>
                {template === "corporategovernance" && (
                  <CorporateGovernance
                    userId={userId}
                    onSuccess={handleClose}
                  />
                )}
                {template === "businessandcontractlaw" && (
                  <BusinessAndContractLaw
                    userId={userId}
                    onSuccess={handleClose}
                  />
                )}
                {template === "laborandemployment" && (
                  <LaborAndEmployment userId={userId} onSuccess={handleClose} />
                )}
                {template === "intellectualproperty" && (
                  <IntellectualProperty
                    userId={userId}
                    onSuccess={handleClose}
                  />
                )}
                {template === "mergersandacquisitions" && (
                  <MergersAndAcquisitions
                    userId={userId}
                    onSuccess={handleClose}
                  />
                )}
                {template === "businesslegaladvisory" && (
                  <BusinessLegalAdvisory
                    userId={userId}
                    onSuccess={handleClose}
                  />
                )}
                {template === "otherlegal" && (
                  <OtherLegal userId={userId} onSuccess={handleClose} />
                )}
              </>
            )}
          </div>
        </div>
      </div >
    )
  );
}

import React, { useEffect, useRef, useState } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../../firebase/firebaseConfig";
import { FaChevronDown, FaCog, FaSignOutAlt, FaUser } from "react-icons/fa"; // Import Font Awesome Icon
import { Link } from "react-router-dom";

export const TopNavbar = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const [user, setUser] = useState({
    name: "",
    username: "",
    profileImage: "",
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userId = user.uid;
        const userDoc = doc(db, "users", userId);
        getDoc(userDoc)
          .then((userSnapshot) => {
            if (userSnapshot.exists()) {
              const data = userSnapshot.data();
              setUser({
                name: data.name,
                username: data.username,
                profileImage: data.profileImage,
              });
            } else {
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      } else {
        console.log("No user is signed in.");
      }
    });

    return () => unsubscribe();
  }, [db, auth]);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("User signed out");
        // Additional logout logic here...
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="text-black w-full p-2 z-10 ">
      <div className="flex justify-end items-center">
        <div className="relative" ref={dropdownRef}>
          <button
            onClick={() => setDropdownVisible(!dropdownVisible)}
            className="flex items-center space-x-2" // Added space-x-2 to create spacing between elements within the button
          >
            <img
              src={user.profileImage}
              alt=""
              className="w-8 h-8 object-cover rounded-full"
            />
            <span className="text-sm">{user.name}</span>
            <FaChevronDown />
          </button>
          {dropdownVisible && (
            <ul
              className="absolute right-0 mt-2 bg-white text-black p-2 rounded shadow-lg transition-transform transform scale-95 origin-top opacity-0"
              style={{ transition: "transform 0.2s ease, opacity 0.2s ease" }}
            >
              {/* <li className="mb-2 flex items-center space-x-2 hover:bg-gray-100 px-2 py-1 rounded">
                <FaUser />
                <Link to="/profile">PROFILE</Link>
              </li>
              <li className="mb-2 flex items-center space-x-2 hover:bg-gray-100 px-2 py-1 rounded">
                <FaCog />
                <Link to="/settings">SETTINGS</Link>
              </li> */}
              <li className="flex items-center space-x-2 hover:bg-gray-100 px-2 py-1 rounded cursor-pointer">
                <FaSignOutAlt />
                <button onClick={handleLogout} className="focus:outline-none">
                  LOGOUT
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

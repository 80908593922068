import React, { useEffect, useState } from "react";
import { auth, db } from "../../firebase/firebaseConfig";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Task from "./Task";
import { ToastContainer } from "react-toastify";
import { Outlet } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { collection, updateDoc, doc, query, onSnapshot } from "firebase/firestore";
import TaskModal from "./TaskModal";

const TaskBoard = () => {
  const [userId, setUserId] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [isTaskModalOpen, setTaskModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([
    { id: "todo", name: "Tasks Queue", tasks: [] },
    { id: "inprogress", name: "In Progress", tasks: [] },
    { id: "done", name: "Completed", tasks: [] },
  ]);

  const handleOpenTaskModal = () => {
    setTaskModalOpen(true);
  };

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const startColumn = columns.find((col) => col.id === source.droppableId);
    const finishColumn = columns.find((col) => col.id === destination.droppableId);

    const startTaskIds = Array.from(startColumn.tasks);
    const [movedTask] = startTaskIds.splice(source.index, 1);
    const finishTaskIds = Array.from(finishColumn.tasks);
    finishTaskIds.splice(destination.index, 0, movedTask);

    const newColumns = columns.map((col) => {
      if (col.id === startColumn.id) return { ...col, tasks: startTaskIds };
      if (col.id === finishColumn.id) return { ...col, tasks: finishTaskIds };
      return col;
    });

    setColumns(newColumns);

    if (startColumn !== finishColumn) {
      try {
        const userId = auth.currentUser.uid;
        const taskRef = doc(db, `users/${userId}/tasks`, draggableId);
        await updateDoc(taskRef, {
          columnType: finishColumn.id,
        });
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
  };

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userId = user.uid;
        setUserId(user.uid);
        const tasksCollection = collection(db, `users/${userId}/tasks`);
        const q = query(tasksCollection);

        const unsubscribeSnapshot = onSnapshot(q, (snapshot) => {
          const tasksData = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            tasksData.push({
              id: doc.id,
              name: data.name,
              columnType: data.columnType,
            });
          });

          const newColumns = columns.map((col) => ({
            ...col,
            tasks: tasksData.filter((task) => task.columnType === col.id),
          }));

          setTasks(tasksData);
          setColumns(newColumns);
        });

        return () => unsubscribeSnapshot();
      } else {
        console.log("No user is signed in.");
      }
    });

    return () => unsubscribeAuth();
  }, [db, auth, columns]);

  const handleCloseModal = () => {
    setTaskModalOpen(false);
  };

  return (
    <div className="w-full px-4 sm:px-8 no-scrollbar">
      <ToastContainer />
      <div className="flex flex-wrap pt-4 justify-between items-center mb-6">
        <button
          className="bg-virtlaw text-white px-6 py-2 rounded-md hover:bg-virtlaw/90 transition duration-300"
          onClick={handleOpenTaskModal}
        >
          Create A Task
        </button>
      </div>

      <div className="w-full no-scrollbar ">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="all-lists" direction="vertical" type="list">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="flex flex-col sm:flex-row sm:justify-start sm:items-start space-y-4 sm:space-y-0 sm:space-x-6 overflow-auto pb-4"
              >
                {columns?.map((column, i) => (
                  <div
                    key={column.id}
                    className="flex flex-col w-full sm:w-auto flex-grow bg-white rounded-lg border p-4 space-y-4 shadow-lg mb-4 sm:mb-0"
                  >
                    <div className="flex items-center justify-between text-gray-700 border-b pb-2">
                      <h3 className="text-lg font-semibold">{column.name}</h3>
                      <span className="text-white text-sm px-3 py-1 rounded-full bg-virtlaw">
                        {column.tasks.length}
                      </span>
                    </div>
                    <Droppable
                      droppableId={column.id}
                      type="task"
                      direction="vertical"
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className={`flex flex-col space-y-4 ${snapshot.isDraggingOver ? "bg-gray-200" : ""}`}
                        >
                          {column.tasks?.map((task, i) => (
                            <Draggable
                              key={task.id}
                              draggableId={task.id}
                              index={i}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Task taskId={task.id} userId={userId} />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <TaskModal
        isOpen={isTaskModalOpen}
        onClose={handleCloseModal}
        userId={userId}
      />
      <Outlet />
    </div>
  );

};

export default TaskBoard;

import React, { useState } from "react";
import { auth } from "../../../firebase/firebaseConfig";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebaseConfig"; // Adjust this import to your Firebase configuration path
import { useUser } from "../../../firebase/UserContext"; // Adjust the path to your UserContext
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { setIsAdmin } = useUser(); // Use the context to set admin status

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");

    try {
      const { user } = await signInWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password
      );

      // Fetch user data from Firestore
      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setIsAdmin(!!userData.isAdmin); // Set the admin status in context
        navigate("/dashboard"); // Navigate to dashboard
      } else {
        // Handle no user data found
        console.error("No user data found");
      }
    } catch (error) {
      console.error("Login Error:", error.message);
      setError("Invalid email or password. Please try again.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const handleForgotPassword = async () => {
    if (!credentials.email) {
      setError("Please enter your email to reset your password.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, credentials.email);
      setMessage("Password reset email sent. Please check your inbox.");
    } catch (error) {
      console.error("Reset Password Error:", error.message);
      setError("Failed to send password reset email. Please try again.");
    }
  };

  return (
    <form
      onSubmit={handleLogin}
      className="bg-white shadow-xl rounded-lg p-8 mb-4"
    >
      {error && <div className="text-red-500 mb-4">{error}</div>}
      {message && <div className="text-green-500 mb-4">{message}</div>}

      <div className="mb-4">
        <label
          htmlFor="email"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          Email:
        </label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          value={credentials.email}
          onChange={handleChange}
          required
          className="shadow appearance-none border outline-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:ring focus:ring-blue-200 focus:ring-opacity-50"
        />
      </div>

      <div className="mb-6">
        <label
          htmlFor="password"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          Password:
        </label>
        <input
          type="password"
          id="password"
          name="password"
          placeholder="Password"
          value={credentials.password}
          onChange={handleChange}
          required
          className="shadow appearance-none outline-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:ring focus:ring-blue-200 focus:ring-opacity-50"
        />
      </div>

      <div className="flex items-center justify-between">
        <button
          type="submit"
          className="bg-[#0C404C] hover:bg-[#083D47] text-white font-bold py-2 px-4 rounded focus:ring focus:ring-[#0C404C] focus:ring-opacity-50"
        >
          Sign In
        </button>
        <button
          type="button"
          onClick={handleForgotPassword}
          className="text-sm text-virtlaw hover:text-virtlawlight"
        >
          Forgot Password?
        </button>
      </div>
    </form>
  );
};

export default LoginForm;

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faCheckCircle, faClock, faFile } from "@fortawesome/free-solid-svg-icons";
import { auth, db } from "../firebase/firebaseConfig"; // Make sure to import auth
import { doc, getDoc } from "firebase/firestore";
import CalendarView from "./components/CalendarView";


const DashboardPage = () => {
  const [userPlan, setUserPlan] = useState(null);

  useEffect(() => {
    const fetchUserPlan = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userId = currentUser.uid;
        const docRef = doc(db, "users", userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setUserPlan(docSnap.data().plan);
        } else {
          console.log("No such document!");
        }
      } else {
        console.log("No user is currently logged in.");
      }
    };

    fetchUserPlan();
  }, []);

  const getAvailableTasks = (plan) => {
    switch (plan) {
      case "essential":
        return 1;
      case "signature":
        return 8;
      case "elite":
        return "Unlimited";
      default:
        return 0;
    }
  };

  const getConsultingHours = (plan) => {
    switch (plan) {
      case "essential":
        return 0;
      case "signature":
        return 1;
      case "elite":
        return 2;
      default:
        return 0;
    }
  };

  const getTemplates = (plan) => {
    switch (plan) {
      case "essential":
        return 0;
      case "signature":
        return 4;
      case "elite":
        return "Unlimited";
      default:
        return 0;
    }
  };

  const cardData = [
    {
      title: "Available Tasks",
      value: getAvailableTasks(userPlan),
      color: "bg-virtlaw/80",
      icon: faList,
    },
    {
      title: "Completed Tasks",
      value: 0, // Replace with dynamic data if necessary
      color: "bg-virtlaw/80",
      icon: faCheckCircle,
    },
    {
      title: "Consulting Hours",
      value: getConsultingHours(userPlan),
      color: "bg-virtlaw/80",
      icon: faClock,
    },
    {
      title: "Templates",
      value: getTemplates(userPlan),
      color: "bg-virtlaw/80",
      icon: faFile,
    },
  ];

  return (
    <div className="p-4 sm:p-8 space-y-4 bg-black-100 rounded-md">
      <h2 className="text-lg sm:text-xl font-bold mb-4">Overview</h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {cardData.map((card, index) => (
          <div
            key={index}
            className={`p-4 sm:p-6 rounded-lg ${card.color} shadow-md text-black`}
          >
            <div className="mb-4">
              <FontAwesomeIcon
                icon={card.icon}
                size="lg"
                className="text-white"
              />
            </div>
            <p className="text-xs sm:text-sm text-white">{card.title}</p>
            <p className="text-md sm:text-lg font-bold text-white">{card.value}</p>
          </div>
        ))}
      </div>
      <div>
        <CalendarView />
      </div>
      {/* <Charts /> */}
    </div>
  );
};

export default DashboardPage;
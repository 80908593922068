import React, { useState, useEffect } from "react";
import { auth, db } from "../firebase/firebaseConfig";
import { addDoc, collection, query, onSnapshot } from "firebase/firestore";

export default function TemplatesPage() {
  const [templateRequest, setTemplateRequest] = useState("");
  const [requestedTemplates, setRequestedTemplates] = useState([]);
  const user = auth.currentUser;

  const fetchRequestedTemplates = () => {
    if (!user) return;

    const q = query(collection(db, `users/${user.uid}/templateRequests`));
    onSnapshot(q, (querySnapshot) => {
      setRequestedTemplates(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    });
  };

  useEffect(() => {
    fetchRequestedTemplates();
  }, [user]); // Fetch requested templates when user state changes

  const handleTemplateRequest = async (e) => {
    e.preventDefault();
    if (!templateRequest.trim()) {
      alert('Please enter a template name.');
      return;
    }
    try {
      await addDoc(collection(db, `users/${user.uid}/templateRequests`), {
        templateName: templateRequest,
        requestedAt: new Date(),
      });
      setTemplateRequest(''); // Clear the form
      alert('Your template request has been submitted.');
    } catch (error) {
      console.error("Error submitting template request: ", error);
      alert('There was an error submitting your request. Please try again.');
    }
  };

  return (
    <div className="space-y-6 p-6 max-w-4xl mx-auto bg-white rounded-md  mt-6">
      <div className="bg-virtlaw rounded-md p-4 text-white mb-6">

        <h3 className="text-lg font-semibold">Good News from the Innovation Desk! 🌟</h3>
        <p>
          Picture this: a digital library brimming with all the legal templates you could dream of, all at your fingertips. We're bustling away at Virtlaw HQ to turn that dream into a reality! While we're dotting the i's and crossing the t's, you can request any template you need, and we'll deliver it straight to your inbox within 24 hours – talk about express service! Thanks for being part of our journey to revolutionize legal services.
        </p>
      </div>
      {/* Form to request a new template */}
      <form onSubmit={handleTemplateRequest} className="space-y-6">
        <h2 className="text-lg font-semibold text-gray-700">Request a Contract Template</h2>
        <label htmlFor="templateRequest" className="block">
          <span className="text-gray-700">What Contract Template Would You Like to Request?</span>
          <input
            type="text"
            id="templateRequest"
            value={templateRequest}
            onChange={(e) => setTemplateRequest(e.target.value)}
            placeholder="Enter the template name"
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-virtlaw focus:border-virtlaw"
            required
          />
        </label>
        <button
          type="submit"
          className="w-full bg-virtlaw hover:bg-virtlaw/90 text-white py-2 px-4 rounded"
        >
          Submit Request
        </button>
      </form>

      {/* Display requested templates */}
      <div>
        <h3 className="text-lg font-semibold text-gray-700">Requested Templates</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
          {requestedTemplates.map((template) => (
            <div key={template.id} className="p-4 bg-gray-100 rounded-md shadow-md">
              <h4 className="text-md font-semibold mb-2">{template.templateName}</h4>
              <p className="text-sm text-gray-600">Requested on: {template.requestedAt.toDate().toLocaleDateString()}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}






// import { faCheckCircle, faClock, faFile, faList } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import React, { useState, Suspense } from 'react';
// import { AiFillAlert } from 'react-icons/ai';
// import { BiSolidBusiness, BiSolidFactory } from 'react-icons/bi';
// import { FaRegMoneyBillAlt } from 'react-icons/fa';
// import { GiOfficeChair } from 'react-icons/gi';
// import { LiaCopyrightSolid } from 'react-icons/lia';
// import { MdBusinessCenter } from 'react-icons/md';
// import CorporateGovernance from './Task/templates/CorporateGovernance';
// import BusinessAndContractLaw from './Task/templates/BusinessAndContractLaw';
// import LaborAndEmployment from './Task/templates/LaborAndEmploymentLaw';
// import IntellectualProperty from './Task/templates/IntellectualProperty';
// import MergersAndAcquisitions from './Task/templates/MergersAndAcquisitions';
// import BusinessLegalAdvisory from './Task/templates/BusinessLegalAdvisory';
// import OtherLegal from './Task/templates/OtherLegal';
// import CoporateGovernanceTemplates from './templates/CorporateGovernance/CorporateGovernanceTemplates';
// import { Link } from 'react-router-dom';

// const LazyNDAContractComponent = React.lazy(() => import('./templates/CorporateGovernance/CorporateGovernanceContractTemplates/NDAContractComponent'));
// // ... similar imports for other templates

// // This object should be defined to map template IDs to the dynamic component.
// const templateComponents = {
//   1: LazyNDAContractComponent,
//   // ... map other template IDs to their respective lazy-loaded components
// };

// const templates = [
//   { id: 1, name: "NDA Contract Template", description: "This is a sample NDA contract template." },
//   // ... other templates
// ];

// function TemplatesPage({ isOpen, onClose, userId }) {
//   const [selectedTemplate, setSelectedTemplate] = useState(null);
//   const cardData = [
//     { title: "Used Templates", value: 1, color: "bg-virtlaw/80", icon: faList },
//     {
//       title: "Remaining Templates",
//       value: 4,
//       color: "bg-virtlaw/80",
//       icon: faCheckCircle,
//     },
//   ];

//   const [template, setTemplate] = useState("");
//   const cardStyles =
//     "flex flex-col cursor-pointer h-[200px] items-start px-4 justify-center space-y-2 bg-white text-black rounded-lg shadow-md transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105";
//   const titleStyles = "text-md font-semibold mb-2";

//   const handleClose = () => {
//     if (onclose) {
//       onclose();
//     }
//     setTemplate("");
//   };

//   const handleSelectTemplate = (templateType) => {
//     setTemplate(templateType);
//   };

//   const handleGoBack = () => {
//     setTemplate("");
//   };

//   // Access the component based on the selected template ID.
//   const TemplateForm = templateComponents[selectedTemplate] || null;

//   return (
//     <div>
//       <div className="flex space-x-4">
//         {cardData.map((card, index) => (
//           <div
//             key={index}
//             className={`p-6 w-1/4 rounded-lg ${card.color} shadow-md text-black`}
//           >
//             <div className="mb-4">
//               <FontAwesomeIcon
//                 icon={card.icon}
//                 size="2x"
//                 className="text-white"
//               />
//             </div>
//             <p className="text-sm text-white">{card.title}</p>
//             <p className="text-lg font-bold text-white">{card.value}</p>
//           </div>
//         ))}
//       </div>
//       <div className="flex mt-6 flex-col items-center justify-center space-y-4">

//         <h2 className="text-2xl font-semibold mb-4">
//           Please Select A Template Area
//         </h2>
//         <div className="flex flex-col">
//           <div className="flex flex-row space-x-4">
//             {/* corporate governance */}
//             <Link
//               to="/templates/corporate-governance-templates"
//               className={cardStyles}
//             >
//               <div className="flex flex-row justify-start space-x-3 items-center">
//                 <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
//                   <GiOfficeChair className="text-2xl text-virtlawlight" />
//                 </div>
//                 <h3 className={titleStyles}>Corporate Governance</h3>
//               </div>
//               <p className="text-sm text-left mb-4">
//                 Set up your company profile, add your company logo, and more
//               </p>
//               <div className="mt-4">
//                 <button className="text-virtlaw text-sm mt-4 hover:underline">Explore Templates</button>
//               </div>
//             </Link>

//             {/* business and contract  */}
//             <div
//               onClick={() => handleSelectTemplate("businessandcontractlaw")}
//               className={cardStyles}
//             >
//               <div className="flex flex-row justify-start space-x-3 items-center">
//                 <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
//                   <BiSolidBusiness className="text-virtlawlight text-2xl" />
//                 </div>
//                 <h3 className={titleStyles}> Business and Contract Law</h3>
//               </div>
//               <p className="text-sm text-left mb-4">
//                 Set up your company profile, add your company logo, and more
//               </p>
//               <div className="mt-4">
//                 <button className="text-virtlaw text-sm mt-4 hover:underline">Explore Templates</button>
//               </div>
//             </div>

//             {/* labor and employment */}

//             <div
//               onClick={() => handleSelectTemplate("laborandemployment")}
//               className={cardStyles}
//             >
//               <div className="flex flex-row justify-start space-x-3 items-center">
//                 <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
//                   <BiSolidFactory className="text-virtlawlight text-2xl" />
//                 </div>
//                 <h3 className={titleStyles}> Labor and Employment Law</h3>
//               </div>
//               <p className="text-sm text-left mb-4">
//                 Set up your company profile, add your company logo, and more
//               </p>
//               <div className="mt-4">
//                 <button className="text-virtlaw text-sm mt-4 hover:underline">Explore Templates</button>
//               </div>
//             </div>
//           </div>

//           {/* intellectual property  */}
//           <div className="flex flex-row space-x-4 mt-4">
//             <div
//               onClick={() => handleSelectTemplate("intellectualproperty")}
//               className={cardStyles}
//             >
//               <div className="flex flex-row justify-start space-x-3 items-center">
//                 <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
//                   <LiaCopyrightSolid className="text-virtlawlight text-2xl" />
//                 </div>
//                 <h3 className={titleStyles}>Intellectual Property</h3>
//               </div>
//               <p className="text-sm text-left mb-4">
//                 Set up your company profile, add your company logo, and more
//               </p>
//               <div className="mt-4">
//                 <button className="text-virtlaw text-sm mt-4 hover:underline">Explore Templates</button>
//               </div>
//             </div>
//             {/*  mergersandacquisitions */}
//             <div
//               onClick={() => handleSelectTemplate("mergersandacquisitions")}
//               className={cardStyles}
//             >
//               <div className="flex flex-row justify-start space-x-3 items-center">
//                 <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
//                   <FaRegMoneyBillAlt className="text-virtlawlight text-2xl" />
//                 </div>
//                 <h3 className={titleStyles}>Mergers And Acquisitions</h3>
//               </div>
//               <p className="text-sm text-left mb-4">
//                 Set up your company profile, add your company logo, and more
//               </p>
//               <div className="mt-4">
//                 <button className="text-virtlaw text-sm mt-4 hover:underline">Explore Templates</button>
//               </div>
//             </div>
//             {/* mergers and acquisitions */}
//             <div
//               onClick={() => handleSelectTemplate("businesslegaladvisory")}
//               className={cardStyles}
//             >
//               <div className="flex flex-row justify-start space-x-3 items-center">
//                 <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
//                   <MdBusinessCenter className="text-virtlawlight text-2xl" />
//                 </div>
//                 <h3 className={titleStyles}>Business Legal Advisory</h3>
//               </div>
//               <p className="text-sm text-left mb-4">
//                 Set up your company profile, add your company logo, and more
//               </p>
//               <div className="mt-4">
//                 <button className="text-virtlaw text-sm mt-4 hover:underline">Explore Templates</button>
//               </div>
//             </div>
//           </div>
//           {/* otherlegal */}
//           <div className="flex mt-4 items-center justify-center">
//             <div
//               onClick={() => handleSelectTemplate("otherlegal")}
//               className={cardStyles}
//             >
//               <div className="flex flex-row justify-start space-x-3 items-center">
//                 <div className="flex items-center justify-center h-10 w-10 bg-virtlaw rounded-full mb-4">
//                   <AiFillAlert className="text-virtlawlight text-2xl" />
//                 </div>
//                 <h3 className={titleStyles}>Other Legal Tasks</h3>
//               </div>
//               <p className="text-sm text-left mb-4">
//                 Set up your company profile, add your company logo, and more
//               </p>
//               <div className="mt-4">
//                 <button className="text-virtlaw text-sm mt-4 hover:underline">Explore Templates</button>
//               </div>
//             </div>
//           </div>
//         </div>


//       </div>
//     </div >
//   );
// }

// export default TemplatesPage;



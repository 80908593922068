

import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import LoginForm from "../components/partials/auth/LoginForm";
import Social from "../components/partials/auth/Social";

const phrases = [
  "Order your legal matters like you order a pizza",
  "Get your tasks delivered in 24 to 72h",
  "Get your peace of mind back",
];

const Login = () => {
  const typeTargetRef = useRef(null);

  useEffect(() => {
    if (typeTargetRef.current) {
      const options = {
        strings: phrases,
        typeSpeed: 50,
        backSpeed: 30,
        showCursor: false,
      };

      const typed = new Typed(typeTargetRef.current, options);

      return () => {
        typed.destroy();
      };
    }
  }, []);

  return (
    <div className="flex flex-wrap h-screen bg-virtlaw">
      <div className="w-full md:w-1/2 flex flex-col justify-start items-start p-10">
        <img
          src="/assets/images/logo/logo.webp"
          alt="Logo"
          className="mb-10 w-[150px]"
        />
        <div className="text-white text-3xl md:text-5xl flex items-center justify-center h-full">
          <span ref={typeTargetRef}></span>
        </div>
      </div>

      <div className="w-full md:w-1/2 flex flex-col justify-between bg-white p-12">
        <div>
          <h4 className="text-2xl font-medium mb-4">Sign in</h4>
          <p className="text-gray-500 mb-6">
            Sign in to your account to start using virtlaw
          </p>
          <LoginForm />
        </div>

        <div className="mt-6">
          <div className="text-sm text-center text-gray-500">
            Don’t have an account?
            <a
              href="https://virtlawus.com/pricing"
              className="text-gray-900 font-medium ml-1 hover:underline"
            >
              Get a Plan
            </a>
          </div>
          <footer className="text-center text-sm text-gray-500 mt-4">
            Copyright 2023, Virtlaw All Rights Reserved.
          </footer>
        </div>
      </div>
      <style jsx>{`
        .typewriter h1 {
          overflow: hidden;
          border-right: 0.15em solid orange;
          white-space: nowrap;
          letter-spacing: 0.15em;
          animation: typing 3.5s steps(40, end),
            blink-caret 0.75s step-end infinite;
        }

        @keyframes typing {
          from {
            width: 0;
          }
          to {
            width: 100%;
          }
        }

        @keyframes blink-caret {
          from,
          to {
            border-color: transparent;
          }
          50% {
            border-color: orange;
          }
        }
      `}</style>
    </div>
  );
};

export default Login;

